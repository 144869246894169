@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.img-equipe {
  height: auto;
  margin: 0 400px;
}
  
.timeline {
  border-left: 1px solid $tertiary;
  position: relative;
  list-style: none;
}