@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.insta__card {
    max-width: 400px;
    margin: 0 auto;
}

.insta__card .card-img-top {
    width: 200px; /* Assure-toi que l'image ou la vidéo occupe toute la largeur de la carte */
    height: 200px; /* Ajuste la hauteur selon tes besoins */
    object-fit: cover; /* Fait en sorte que l'image/vidéo remplisse son conteneur sans déformation */
}

/* Optionnel : Ajuste la hauteur ou le comportement spécifique pour les vidéos */
.insta-media {
    object-fit: cover; /* Comme pour les images, assure que la vidéo s'ajuste bien */
    border: 12px solid var(--secondary);
    border-radius: 25px;
}
