@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/variables.scss";

.footer__container {
  margin: 25px;
  color: $secondary;
  &--logo {
    max-width: 300px;
  }
  &--text {
    border-top : solid 2px $secondary;
    padding : 30px;
    ul li {
      strong {
        font-family: "Lato Bold", sans-serif;
        font-size: 21px;
      }
      &:first-child {
        margin-bottom: 15px;
      }
    }
  } 
}

.small__text {
  margin-left: 32px;
  &--link {
    &:hover {
      color : $tertiary;
    }
  }
}