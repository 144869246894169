@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.notfound {
    margin-top: 80px;
    height: 50vh;
    width: auto;
    object-fit: contain; /* Assure que l'image garde son ratio */
    &__link {
        margin-top: 20px;
        text-align: center;
        align-self: center;
        color: var(--secondary);
        font-size: 22px;
        &:hover {
            color: var(--tertiary); /* Change la couleur au survol */
        }
    }
}