@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.CardServiceInfo {
    white-space: pre-line;
    height: 450px;
    &__image{
        position: relative;
        height: 450px;
        object-fit: cover;
    }
    &__overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
    &__title {
        font-family: Lato Bold;
        color: white;
        font-size: 35px;
        text-transform: uppercase;
        text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.5);
        z-index: 2;
    }
}
  
.CardServiceInfo__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    &--content{
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 500px;
        width: 90%;
        text-align: start;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
        animation: fadeIn 0.3s ease;
        z-index: 4;
        &-title{
            font-family: Lato Bold;
            font-size: 20px !important;
            color: $secondary !important;
        }
        &-link{
            font-family: Lato Bold;
            color: $tertiary !important;
        }
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}