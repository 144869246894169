@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.mentions-content {
    padding: 2rem;
    > *:not(:last-child) {
    margin-bottom: 2rem;
    }
}

.title {
    font-size: 1.6rem;
    margin-bottom: 0.5em;
    font-family: "Lato Bold", sans-serif;
}