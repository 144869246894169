@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.googlecard {
  border-radius: 8px;
  overflow: hidden;
}

.googlecard-body {
  padding: 1.25rem;
}

.googlecard-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ddd;
}

.googlecard-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: $tertiary;
}

.googlecard-stars {
  font-size: 1.25rem; // Ajustez la taille des étoiles si nécessaire
}

.googlecard-stars .fa-star {
  margin-right: 0.1rem; // Espacement entre les étoiles
}

.googlecard-date {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.googlecard-content {
  font-size: 1rem;
  color: $secondary;
}