@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.CardMarque {
    position: relative;
    height: 450px;
    &__image{
        position: relative;
        height: 450px;
        object-fit: cover;
    }
    &__overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
    &__title {
        font-family: Lato Bold;
        color: white;
        font-size: 35px;
        text-transform: uppercase;
        text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.5);
        z-index: 2;
    }
}
  