@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.banner {
    background-image: url("../../../public/images/banner.webp");
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 120px; /* Ajusté pour mobile par défaut */
        height: auto; /* Ajusté pour mobile */
        width: 90%;
        left: 5%;
        font-family: "Lato Regular";
        font-size: 18px; /* Par défaut pour mobile */
        color: $primary;
        &--logo {
            height: auto;
            width: 300px; /* Mobile: largeur réduite */
            margin-bottom: -30px;
        }

        &--text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: $primary;
            }
        }

        /* Écrans larges (Large) */
        @include Small {
            flex-direction: row;
            align-items: flex-end;
            bottom: 150px; /* Ajusté pour desktop */
            height: 300px;
            font-size: 24px; /* Plus grande taille de police pour desktop */

            &--logo {
                height: 100%;
                width: auto; /* Largeur pour desktop */
                margin-bottom: -50px;
            }

            &--text {
                flex-direction: row; /* Aligner le texte et le logo horizontalement */
                justify-content: space-between;
                width: 80%;
                padding-left: 40px;
                padding-right: 40px;
                &::before {
                    width: 90%;
                }
            }
        }
    }

    &__marquee {
        display: flex;
        position: absolute;
        color: $primary;
        font-family: "Lato Bold";
        text-transform: uppercase;
        font-size: 100px;
        font-weight: 400;
        bottom: 15px;
        left: 0;
        width: 100%;
        height: 130px;
        overflow: hidden;
    }
}
