@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.timeline-item {
    position: relative;
    &::after {
        background-color: $tertiary;
        left: -38px;
        border-radius: 50%;
        height: 11px;
        width: 11px;
        content: "";
        position: absolute;
        display: block;
        top: 0;
    }
}

.date {
    font-family: 'Lato Bold', sans-serif;
}