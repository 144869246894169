@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.marquee {
    display: flex;
    width: 300%;
    height: 100%;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 30s linear infinite;
    &__content {
        display: inline-block;
        padding-right: 0;
    }
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

.marquee__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Aligne le contenu en bas */
    height: 100%;
    @include Small {
        justify-content: flex-end;
    }
}
