@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.navbar {
    font-size: 18px;
}

.navbar-brand{
    margin: 0px;
    padding: 0px;
}

.navbar-nav {
    font-family: Lato Regular ;
}

.nav-bg-color {
    background-color: $primary !important;
}

.nav-link {
    color: $secondary !important;
    transition: color 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
    color: $tertiary !important;
}
  
.nav-effect {
    position: relative;
    overflow-x: hidden;
    &:before {
        content: "";
        position: absolute;
        bottom: 3.5px;
        left: -100%;
        width: 100%;
        height: 2px;
        background-color: $tertiary;
        transition: left 0.3s ease-in-out;
    }
    &:hover:before {
        left: 0;
    }
}