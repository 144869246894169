@import "../utils/variables.scss";
@import "../utils/mixins.scss";

@font-face {
    font-family: "Lato Bold";
    src: local("Lato Bold"), url("../../fonts/Lato/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato Regular";
    src: local("Lato Regular"), url("../../fonts/Lato/Lato-Regular.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato Regular", sans-serif;
}

html,
body {
    background-color: $primary;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

h1 {
    font-family: Lato Bold;
    font-size: 60px;
    @include Small {
        font-size: 90px;
    }
    @include Large {
        font-size: 100px;
    }
}

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 0px;
}

.accueil__banner {
    padding: 0px;
}

.accueil__marquee {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 5px 0;
}

.main__container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 35px;
    p {
        color: $secondary;
        font-size: 16px;
    }
}

section {
    display: flex;
    flex-direction: column;
    gap: 35px;
    .titleUnderline {
        position: relative;
        font-family: Lato Regular;
        color: $secondary;
        font-size: 1.4rem;
        &:before {
            content: "";
            position: absolute;
            bottom: -5px;
            height: 1px;
            background-color: $secondary;
            width: 100%;
        }
    }
}

.btn-primary {
    background-color: $primary;
    color: $tertiary;
    border-color: $tertiary;
    z-index: 2;
    &:hover {
        background-color: $tertiary;
        color: $primary;
        border-color: $tertiary;
    }
    span {
        font-family: Lato Bold;
        font-size: 17px;
    }
}
